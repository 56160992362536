import Cosmic from './Cosmic';


function getPlaces(pagination) {
  return new Promise((resolve, reject) => {
    const params = {
      // type_slug: config.object_type,
      type_slug: 'places',
      limit: pagination.limit,
      skip: (pagination.page - 1) * pagination.limit
    };
    Cosmic.getObjectsByType({
      bucket: {slug: 'tulum', read_key: process.env.VUE_COSMIC_JS_KEY},
      type_slug: 'places'
    }, params, (err, res) => {
      if (!err) {
        resolve(res);
      } else {
        reject({
          err: err
        });
      }
    });
  });
}
function getActivities() {
  return new Promise((resolve, reject) => {
    const params = {
      type_slug: 'routeactivities',
      limit: 10,
      sort: '-created_at',
    };
    Cosmic.getObjectsByType({
      bucket: {slug: 'tulum',
        read_key: process.env.VUE_COSMIC_JS_KEY},
      type_slug: 'routeactivities'
    }, params, (err, res) => {
      if (!err) {
        resolve(res);
      } else {
        reject({
          err: err
        });
      }
    });
  });
}
function getHotels() {
  return new Promise((resolve, reject) => {
    const params = {
      type_slug: 'hotels',
      limit: 10,
    };
    Cosmic.getObjectsByType({
      bucket: {slug: 'tulum',
        read_key: process.env.VUE_COSMIC_JS_KEY},
      type_slug: 'hotels'
    }, params, (err, res) => {
      if (!err) {
        resolve(res);
      } else {
        reject({
          err: err
        });
      }
    });
  });
}
function getFoods() {
  return new Promise((resolve, reject) => {
    const params = {
      type_slug: 'foods',
      limit: 10,
    };
    Cosmic.getObjectsByType({
      bucket: {slug: 'tulum',
        read_key: process.env.VUE_COSMIC_JS_KEY},
      type_slug: 'foods'
    }, params, (err, res) => {
      if (!err) {
        resolve(res);
      } else {
        reject({
          err: err
        });
      }
    });
  });
}
function getFaqs() {
  return new Promise((resolve, reject) => {
    const params = {
      type_slug: 'faqs',
      limit: 10,
      sort: '-created_at',
    };
    Cosmic.getObjectsByType({
      bucket: {slug: 'tulum',
      read_key: process.env.VUE_COSMIC_JS_KEY},
      type_slug: 'faqs'
    }, params, (err, res) => {
      if (!err) {
        resolve(res);
      } else {
        reject({
          err: err
        });
      }
    });
  });
}


export default {getPlaces, getActivities, getFaqs, getHotels, getFoods}
