<template>
  <div class="content" style="margin-top: -50px">
    <div class="row  text-center" style="margin-bottom: 55px; ">
      <!--      <div class="col-md-12">-->
      <!--        <br>-->
      <!--        <img src="https://imgix.cosmicjs.com/e8ee2ea0-88d4-11eb-a1e5-63d55f58b597-route-133.png?h=60">-->
      <!--        <h2 class="route307-subtitle" style="font-size: 0.7rem">-->
      <!--          A path to be re-discovered.-->
      <!--        </h2>-->
      <!--      </div>-->
      <div class="col-md-12">
        <hr>
<!--        <pre>{{places}}</pre>-->
        <div class="text-center ">
          <router-link
            class=" btn-link"
            :class="place === p && 'active'"
            v-for="p in places"
            :to="'/place/'+p.slug"
            :key="p._id"
          >{{ p.title === 'Playa del Carmen'  ? 'PDC' : p.title}}
          </router-link>
        </div>
      </div>
      <div class="col-md-12">
        <h3>{{ hotel.title }}</h3>
        <div style="height:170px">
          <img
            :src="hotel.metadata.image.imgix_url+'?w=400&h=170&fit=crop'"
            :alt="hotel.title">
        </div>
      </div>
    </div>

    <div class="row ">
      <div class="col-sm-12 text-left">
        <div v-html="hotel.content"/>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Badge from "../../components/Badge";

export default {
  name: 'Place',
  title: '[travelers guide]',
  data: () => {
    return {
      selectedCategory: 'activities',
      swiperOption: {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }
  },
  head: {},
  components: {},
  methods: {

  },
  computed: {
    // place: {
    //   get() {
    //     return this.$store.state.places.find(place => this.humanizeTitle(place.title) === this.humanizeTitle(this.$route.params.place));
    //   }
    // },
    hotel: {
      get() {
        // console.log(this.$store.state.hotels[0].slug);
        // console.log(this.$route.params.hotel);
        return this.$store.state.hotels.find(hotel => hotel.slug === this.$route.params.hotel);
      }
    },
    places: {
      get() {
        return this.$store.state.places;
      }
    },
    otherPlaces: {
      get() {
        return this.$store.state.places.filter(p => p !== this.place);
      }
    },
    // activities: {
    //   get() {
    //     return this.$store.state.activities.filter(activity => this.humanizeTitle(activity.metadata.place.title) ===
    //       this.humanizeTitle(this.$route.params.place));
    //   }
    // },
    // hotels: {
    //   get() {
    //     return this.$store.state.hotels.filter(hotel => this.humanizeTitle(hotel.metadata.place.title) ===
    //       this.humanizeTitle(this.$route.params.place));
    //   }
    // },
    // foods: {
    //   get() {
    //     return this.$store.state.foods.filter(food => this.humanizeTitle(food.metadata.place.title) ===
    //       this.humanizeTitle(this.$route.params.place));
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>

</style>
