import MainLayout from '../pages/Layout/MainLayout.vue';
import HomePage from  '../pages/Home/Home.vue'
import Place from '../pages/Place/Place.vue'
import Hotel from '../pages/Hotel/Hotel.vue'



// GeneralViews
// import NotFound from 'template_reference/GeneralViews/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    name: 'Main',
    redirect: {
      name: "Home"
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomePage,
      },
      // {
      //   path: '/playa-del-carmen',
      //   name: 'Playa',
      //   component: PlayaPage,
      // },
      // {
      //   path: '/bacalar',
      //   name: 'Bacalar',
      //   component: BacalarPage,
      // },
      {
        path: '/place/:place',
        name: 'Place',
        component: Place
      },
      {
        path: '/hotel/:hotel',
        name: 'Hotel',
        component: Hotel
      },
    ]
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/'
  // }
];

export default routes
