<template>
  <footer class="footer">
    <div class="row">
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
  .main-panel footer {
    padding-left: 35%;
    align-content: center;
  }
</style>
