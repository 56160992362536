export default {
  install(Vue) {
    let app = new Vue({
      data() {
        console.log("RTLPlugin", "data()")
        return {
          forceRTLRecompute: false,
        }
      },
      methods: {
        getDocClasses() {
          return document.body.classList
        },
        enableRTL() {
          this.getDocClasses().add('rtl');
          this.getDocClasses().add('menu-on-right');
          this.toggleBootstrapRTL(true);
          this.forceRTLRecompute = true // to force recomputing
        },
        disableRTL() {
          this.getDocClasses().remove('rtl');
          this.getDocClasses().remove('menu-on-right');
          this.toggleBootstrapRTL(false);
          this.forceRTLRecompute = false // to force recomputing
        },
        toggleBootstrapRTL(value) {
          for (let i=0; i < document.styleSheets.length; i++){
            let styleSheet = document.styleSheets[i];
            let { href } = styleSheet;
            if(href && href.endsWith('bootstrap-rtl.css')){
              styleSheet.disabled = !value;
            }
          }
        }
      },
      computed: {
        isRTL() {
          this.forceRTLRecompute; // to create a dependancy on the property
          return this.getDocClasses().contains("rtl") || this.getDocClasses().contains("menu-on-right")
        }
      }
    });
    Vue.prototype.$rtl = app;
  }
}
