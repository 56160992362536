<template>
  <router-view></router-view>
</template>

<script>
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYYMMDD')
      }
    },
  },
  mounted() {
    this.initializeLayout();
  }
};


</script>
