<template>
  <div>
    <base-nav
      v-model="showMenu"
      class="navbar-absolute top-navbar waves"
      type="white"
      :transparent="true"
    >
      <div slot="brand" class="fixed-top" style="width: 100%;background-color: #E5E5E5;">
        <div class="navbar-minimize d-inline">
          <sidebar-toggle-button/>
        </div>
        <div class="navbar-toggle text-center" :class="{ toggled: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" @click="toggleSidebar"
                  style="left: 0px;padding:7px;position: fixed;background-color: #E5E5E5">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
          <span class="text-center block " style="display: inline-block;padding: 7px">
            <router-link to="/">
            <img src="https://imgix.cosmicjs.com/939259f0-b272-11eb-bd86-3988be5a9e1c-image.png?h=25" alt="route307">
            </router-link>
          </span>
        </div>
      </div>
    </base-nav>

  </div>
</template>
<script>
import {CollapseTransition} from 'vue2-transitions';
import {BaseNav, Modal} from '@/components';
import SidebarToggleButton from './SidebarToggleButton';

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
  },
  computed: {
    routeName() {
      const {name} = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    artistOfTheWeek() {
      return this.$store.state.artistOfTheWeek
    },
    soundcloudURL() {
      return `https://w.soundcloud.com/player/?url=${this.artistOfTheWeek.track}&color=%23152f44&auto_play=false&show_reposts=false&show_teaser=false&visual=true`;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    showInPage() {
      return true;
    },
    showTulumHeader() {
      return true;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
