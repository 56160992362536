import Vue from 'vue';
import Vuex from 'vuex';
import Request from '../common/request';
import _ from 'lodash';
import moment from 'moment';
import { buildIncomingParties } from '../common/utils';

Vue.use(Vuex);
Vue.use(require('vue-moment'));
// the root, initial state object
const state = {
  activities: [],
  places: [],
  hotels: [],
  foods: [],
  faqs: [],
  artistOfTheWeek: {},
  status: {
    loading: false,
    success: false,
    error: false,
  },
  editForm: false,
  pagination: {
    page: 1,
    limit: 20,
    total: 0,
  },
};

// define the possible getters that can be applied to our state
const getters = {
  places(state) {
    return state.places;
  },
  activities(state) {
    return state.activities;
  },
  hotels(state) {
    return state.hotels;
  },
  foods(state) {
    return state.foods;
  },
  faqs(state) {
    return state.faqs;
  },
  loading(state) {
    return state.status.loading;
  },
  pagination(state) {
    return state.pagination;
  },
  page(state) {
    return state.pagination.page;
  },
};

// define the possible mutations that can be applied to our state
const mutations = {
  SET_TOTAL(state, payload) {
    state.pagination.total = Math.ceil(payload / state.pagination.limit);
  },
  SET_PLACES(state, payload) {
    state.places = _.reverse(payload);
  },
  SET_FAQ(state, payload) {
    state.faqs = _.reverse(payload);
  },
  SET_HOTELS(state, payload) {
    state.hotels = payload;
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },
  SET_FOODS(state, payload) {
    state.foods = payload;
  },
  LOADING(state) {
    state.status = {
      loading: true,
      success: false,
      error: false,
    };
  },
  SUCCESS(state) {
    state.status = {
      loading: false,
      success: true,
      error: false,
    };
  },
  ERROR(state, payload) {
    state.status = {
      loading: false,
      success: false,
      error: payload,
    };
  },
  CLEAR_ERROR(state) {
    state.status = {
      loading: false,
      success: false,
      error: false,
    };
  },

  PAGINATE(state, payload) {
    state.pagination.page = payload;
  },
};

// define the possible actions that can be applied to our state
const actions = {
  getPlaces(context) {
    context.commit('LOADING');
    Request.getPlaces(context.getters.pagination)
      .then((res) => {
        console.log(res);
        context.commit('SET_PLACES', res.objects.all);
        context.commit('SET_TOTAL', res.total || 0);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getActivities(context) {
    context.commit('LOADING');
    Request.getActivities()
      .then((res) => {
        context.commit('SET_ACTIVITIES', res.objects.all);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getHotels(context) {
    context.commit('LOADING');
    Request.getHotels()
      .then((res) => {
        console.log(res);
        context.commit('SET_HOTELS', res.objects.all);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getFoods(context) {
    context.commit('LOADING');
    Request.getFoods()
      .then((res) => {
        console.log(res);
        context.commit('SET_FOODS', res.objects.all);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        console.error(e);
        context.commit('ERROR', e);
      });
  },
  getFaq(context) {
    context.commit('LOADING');
    Request.getFaqs()
      .then((res) => {
        context.commit('SET_FAQ', res.objects.all);
        context.commit('SUCCESS');
      })
      .catch((e) => {
        context.commit('ERROR', e);
      });
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
