import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store/index.js'
// router setup
import router from './routes/router';
import i18n from './i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueHead from 'vue-head'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueGtag from "vue-gtag"
Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));
Vue.use(VueHead);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.use(VueGtag, {
  config: { id: "G-B8JJHM99C7" },
  enabled:  process.env.NODE_ENV === 'production'
});

// plugin setup
Vue.use(DashboardPlugin);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  created() {
    this.$store.dispatch('getPlaces');
    this.$store.dispatch('getActivities');
    this.$store.dispatch('getFaq');
    this.$store.dispatch('getHotels');
    this.$store.dispatch('getFoods');

    // this.$store.dispatch('getVenues');
  },
  router,
  store,
  i18n
});
