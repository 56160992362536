<template>
  <div class="content" style="margin-top: -50px">
    <div class="row  text-center" style="margin-bottom: 55px; ">
      <div class="col-md-12">
        <br>
        <img src="https://imgix.cosmicjs.com/e8ee2ea0-88d4-11eb-a1e5-63d55f58b597-route-133.png?h=60" align="left">
        <p>Riviera Maya Best Destinations</p>
        <small style="color: silver;">Let's walk it together</small>

      </div>
      <div class="col-md-12">
        <hr>
        <div class="text-center ">
          <div v-for="(p, idx) in places"    :key="p._id" style="display: inline-flex">

            <router-link
              style="vertical-align: top"
              class=" btn-link"
              :class="place === p && 'active'"
              :to="p.slug"
            >
              {{ p.title === 'Playa del Carmen' ? 'PDC' : p.title }}
            </router-link>
          </div>
      </div>
      </div>
      <div class="col-md-12">
        <hr>
        <h3 class="route307-title">{{ place.title }}</h3>
        <div style="height:170px">
          <img
            :src="place.metadata.image.imgix_url+'?w=400&h=170&fit=crop'"
            :alt="place.title">
        </div>
      </div>
      <div class="col-sm-12">
        <div class="flex-row">
          <div class="flex-row mt-10" style="margin-top:0px">
            <button class="btn-link" v-on:click="selectedCategory='activities'"
                    :class="selectedCategory ==='activities' && 'active'">
              Activities
            </button>
            <button class=" btn-link" v-on:click="selectedCategory='events'"
                    :class="selectedCategory ==='events' && 'active'">
              Events
            </button>
            <button class=" btn-link" v-on:click="selectedCategory='hotels'"
                    :class="selectedCategory ==='hotels' && 'active'">
              Hotels
            </button>
            <button class="btn-link" v-on:click="selectedCategory='foods'"
                    :class="selectedCategory ==='foods' && 'active'">
              Foods
            </button>
            <button class=" btn-link" v-on:click="selectedCategory='tours'"
                    :class="selectedCategory ==='tours' && 'active'">
              Tours
            </button>
            <button class=" btn-link" v-on:click="selectedCategory='nightlife'"
                    :class="selectedCategory ==='nightlife' && 'active'">
              Nightlife
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row ">
      <div class="col-sm-12 text-left">
        <h3 class="route307-subtitle mb-1">{{ selectedCategory }}</h3>
        <hr>
        <div v-if="selectedCategory === 'activities'">
          <div v-for="activity in activities"
               class="card p-1"
               :key="activity._id">
            <div>
              <img
                style="margin-right: 20px"
                align="left"
                :key="activity._id"
                :src="activity.metadata.image.imgix_url+'?fit=crop&w=100&h=200'"
                :alt="activity.title">
              <div style="padding-left:10px">
                <h4 class="route307-subtitle">{{ activity.title }}</h4>
                <br>
                <div v-html="activity.content" class="md-small-hide"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedCategory === 'hotels'">
          <div v-for="hotel in hotels"
               class="card p-1"
               :key="hotel._id">
            <div>
              <img
                style="margin-right: 20px"
                align="left"
                :src="hotel.metadata.image.imgix_url+'?fit=crop&w=100&h=200'"
                :key="hotel._id"
                :alt="hotel.title">
              <div style="padding-left:10px">
                <router-link :to="'/hotel/'+hotel.slug">

                  <h4 class="route307-subtitle">{{ hotel.title }}</h4>
                  <br>
                  <div v-html="hotel.metadata.summary" class="md-small-hide"></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedCategory === 'foods'">
          <div v-for="food in foods"
               class="card p-1"
               :key="food._id">
            <div>
              <img
                style="margin-right: 20px"
                align="left"
                :src="food.metadata.image.imgix_url+'?fit=crop&w=100&h=200'"
                :key="food._id"
                :alt="food.title">
              <div style="padding-left:10px">
                <h4 class="route307-subtitle">{{ food.title }}</h4>
                <br>
                <div v-html="food.summary" class="md-small-hide"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Badge from "../../components/Badge";

export default {
  name: 'Place',
  title: '[travelers guide]',
  data: () => {
    return {
      selectedCategory: 'activities',
      swiperOption: {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }
  },
  head: {},
  components: {Badge},
  methods: {
    humanizeTitle: (title) => {
      console.log(title);
      return title.toLowerCase().replace(/\s/g, "-")
    }
  },
  computed: {
    place: {
      get() {
        return this.$store.state.places.find(place => this.humanizeTitle(place.title) === this.humanizeTitle(this.$route.params.place));
      }
    },
    places: {
      get() {
        return this.$store.state.places;
      }
    },
    otherPlaces: {
      get() {
        return this.$store.state.places.filter(p => p !== this.place);
      }
    },
    activities: {
      get() {
        return this.$store.state.activities.filter(activity => this.humanizeTitle(activity.metadata.place.title) ===
          this.humanizeTitle(this.$route.params.place));
      }
    },
    hotels: {
      get() {
        return this.$store.state.hotels.filter(hotel => this.humanizeTitle(hotel.metadata.place.title) ===
          this.humanizeTitle(this.$route.params.place));
      }
    },
    foods: {
      get() {
        return this.$store.state.foods.filter(food => this.humanizeTitle(food.metadata.place.title) ===
          this.humanizeTitle(this.$route.params.place));
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
