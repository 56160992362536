<template>
  <div class="content" style="margin-top: -50px">
    <div class="row  text-center" style="margin-bottom: 1px; ">
      <div class="col-md-12">
        <br>
        <img src="https://imgix.cosmicjs.com/e8ee2ea0-88d4-11eb-a1e5-63d55f58b597-route-133.png?h=60" align="left">
        <p>Riviera Maya Best Destinations</p>
        <small style="color: silver;">Let's walk it together</small>
        <a href="sms:+524422736585&body=JOIN" style="color:black;text-transform: uppercase"
           class="btn btn-sm btn-primary">
          Join the community
        </a>
        <br>
        <p class="drive floating">{{ vehicle }}</p>
        <hr>

      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">

      </div>
    </div>
    <div class="row md:hidden">
      <div class="col-sm-12 ">
        <div class="row">
          <div class="col-sm-12" v-for="(place, idx ) in places" :key="idx">
            <router-link :to="'/place/'+ place.slug" class="simple-text logo-normal">
              <h4 class="font-weight-bold">
                <Badge style="width: 30px" :type="['primary', 'danger', 'info'][idx]">{{ idx + 1 }}</Badge>
                {{ place.title }}
              </h4>
              <div class="card">
                <div style="height: 250px;display: inline-flex;">
                  <Badge :type="['primary', 'danger', 'info'][idx]"
                         style="height: 250px;width: 30px;text-orientation: upright;writing-mode:vertical-lr;display: inline;">
                    Route
                    307
                  </Badge>
                  <img data-v-3bf6d6bb="" style="vertical-align: top"
                       :src="place.metadata.image.imgix_url+'?fit=fill&h=250&w=300'" :alt="place.title">
                  <div style="display: inline;color:black;vertical-align: top;margin: 0.8rem;" v-html="place.content">
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">
        <img
          src="https://imgix.cosmicjs.com/74255a40-88e7-11eb-afec-8949c9a8cfb0-360px-Carreterafederal307.svg.png"
          align="center"
          style="margin-right: 10px"
          width="40px">
        <p style="font-weight: bolder">Move across the Riviera Maya finding hidden gems</p>
        <p>Mexico Federal Highway 307 connects Cancún, Quintana Roo to Chetumal and Palenque to La Trinitaria,
          Chiapas.</p>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>

import 'swiper/css/swiper.css'
import {SwiperSlide} from 'vue-awesome-swiper'
import Badge from "../../components/Badge";

export default {
  name: 'Home',
  title: '[travelers guide] Riviera Maya Travelers Guide - ',
  data: () => {
    return {
      modal: {},
      placeMenu: 'Playa del Carmen',
      swiperOption: {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }
  },
  head: {},
  components: {
    Badge,
  }, methods: {

    selectedPlace() {
      this.selectedPlace = 'Bacalar'
    },

    showMore(id) {
      this.modal = {};
      this.modal[id] = true;
    },
    showLess(id) {
      this.modal = {};
      this.modal[id] = false;
    },
    badgeType(idx) {
      // return ['primary', 'warning', 'info', 'danger'][idx % 4];
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    }
  },
  computed: {
    places: {
      get() {
        return this.$store.state.places;
      }
    },
    activities: {
      get() {
        return this.$store.state.activities;
      }
    },
    randomDid: {
      get() {
        return Math.floor(Math.random() * Math.floor(5));
      }
    },
    vehicle: {
      get() {
        return ['🚙', '🚁', '🚴', '🛵', '🚎', '🛸', '🏊','🤸','🧘‍️','🦖','🕊 ']
          [Math.floor(Math.random(11) * 10)]
      }
},
// venues: {
//   get() {
//     return this.$store.state.venues;
//   }
// },

}
}
</script>
<style lang="scss" scoped>
</style>
